import React from "react";
import Particles from "react-particles-js";
import { Row, Col, Container } from "react-bootstrap";
import BaffleText from "components/baffle-text";
import AnimationContainer from "components/animation-container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAws } from "@fortawesome/free-brands-svg-icons";
import {
  faPencilRuler,
  faSmileBeam,
  faPizzaSlice,
  faQuoteRight,
  faCode,
  faSeedling,
  faBox,
  faCalendar
} from "@fortawesome/free-solid-svg-icons";
import Counter from "components/counter";
import ThemeContext from "../../context";
import "./styles.scss";

class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
    this.show = this.show.bind(this);
  }

  static contextType = ThemeContext;

  show() {
    this.setState({ show: true });
  }

  render() {
    return (
      <section
        id={`${this.props.id}`}
        className="services"
        style={{ height: this.context.height }}
      >
        <Row
          className="top"
          style={{
            maxHeight:
              this.context.height !== "auto"
                ? this.context.height * 0.8
                : "inherit"
          }}
        >
          <div className="content">
            <Col md={12}>
              <div className="line-text">
                <h4>O proizvodima</h4>
              </div>
              <div className="heading">
                <BaffleText
                  text="Što nudi Terraform studio?"
                  revealDuration={500}
                  revealDelay={500}
                  parentMethod={this.show}
                  callMethodTime={1100}
                />
              </div>
              <div
                className="services_container"
                style={{
                  minHeight:
                    this.context.height !== "auto"
                      ? this.context.height * 0.6
                      : "inherit"
                }}
              >
                <Container>
                  {this.services()}
                  {/* {this.particles()} */}
                </Container>
              </div>
            </Col>
          </div>
        </Row>
        <Row className="bottom">{this.counters()}</Row>
      </section>
    );
  }

  services() {
    if (this.state.show || this.context.height === "auto") {
      return (
        <Row>
          <Col md={4} className="service">
            <AnimationContainer delay={200} animation="fadeInLeft fast">
              <div className="icon">
                <FontAwesomeIcon icon={faSeedling} />
              </div>
              <h4>Biljni terarij</h4>
              <p>
                Izrada biljnog terarija, samoodrživog ekosistema unutar
                zatvorenog kontejnera je poprilično jednostavan poduhvat. Biljke
                u terariju mogu preživjeti i desetljećima bez zalijevanja ili
                dodatne brige. Ponekada, biljke će trebati zraka ili vlage, ali
                to sve ovisi o poziciji terarija u Vašem prostoru. Nudim
                klasične terarije u demižonima različitih litraža i mogućnost
                izrade terarija s lampom. Savršen dodatak za uređenje Vašeg
                omiljenog prostora.
              </p>
            </AnimationContainer>
          </Col>
          <Col md={4} className="service border-side">
            <AnimationContainer delay={400} animation="fadeInDown fast">
              <div className="icon">
                <FontAwesomeIcon icon={faBox} />
              </div>
              <h4>Terarij kit</h4>
              <p>
                Terarij kit sadrži sav materijal potreban za izradu vlastitog
                terarija (uključujući i priručnik s detaljnim uputstvima za
                izradu i njegu terarija). Ovaj savršeni poklon za Vaše bližnje,
                prijatelje ili radne kolege je poput sredstva za opuštanje.
                Postoji li bolja meditacija od rada s biljkama i ponovnom
                povezivanju s majkom prirodom? :)
              </p>
            </AnimationContainer>
          </Col>
          <Col md={4} className="service">
            <AnimationContainer delay={800} animation="fadeInLeft fast">
              <div className="icon">
                <FontAwesomeIcon icon={faPencilRuler} className="solid" />
              </div>
              <h4>Obrada stakla</h4>
              <p>
                Osim klasičnog terarija u demižonu, nudim i unikatne kontejnere
                izrađene tehnikom lemljenja stakla. Nakon što dizajniram oblik i
                izrežem staklo slijedi lemljenje/spajanje stakla i stavljanje
                patine za rustikalniji izgled. Moguća je personalizirana izrada
                za svakog klijenta ovisno o potrebi i prigodi. Više detalja o
                radovima možete pronaći u našem katalogu.
              </p>
            </AnimationContainer>
          </Col>
        </Row>
      );
    }
  }

  counters() {
    if (this.state.show || this.context.height === "auto") {
      return (
        <Container>
          <Col md={3}>
            <AnimationContainer delay={100} animation="fadeIn fast">
              <Counter
                icon={faSmileBeam}
                value={100}
                text="Zadovoljnih kupaca"
                symbol="+"
                duration={3}
              />
            </AnimationContainer>
          </Col>
          <Col md={3}>
            <AnimationContainer delay={100} animation="fadeIn fast">
              <Counter
                icon={faSeedling}
                value={139}
                text="Izrađenih terarija"
                symbol="+"
                duration={3}
              />
            </AnimationContainer>
          </Col>
          <Col md={3}>
            <AnimationContainer delay={100} animation="fadeIn fast">
              <Counter
                icon={faQuoteRight}
                value={40}
                text="Recenzija"
                symbol="+"
                duration={3}
              />
            </AnimationContainer>
          </Col>
          <Col md={3}>
            <AnimationContainer delay={100} animation="fadeIn fast">
              <Counter
                icon={faCalendar}
                value={10}
                text="Godina iskustva"
                symbol="+"
                duration={3}
              />
            </AnimationContainer>
          </Col>
        </Container>
      );
    }
  }

  particles() {
    return (
      <Particles
        className="particles"
        params={{
          particles: {
            number: {
              value: 50,
              density: {
                enable: false,
                value_area: 5000
              }
            },
            color: {
              value: "#eb83f8"
            },
            line_linked: {
              enable: true,
              opacity: 0.5,
              color: "#000"
            },
            size: {
              value: 4
            }
          },
          retina_detect: true
        }}
      />
    );
  }
}

export default Services;
