import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Layout from "components/layout";
import Spinner from "components/spinner";
import Hero from "sections/hero";
import About from "sections/about";
import Portfolio from "sections/portfolio";
import Services from "sections/services";
import Testimonials from "sections/testimonials";
import Contact from "sections/contact";
import CookieConsent from "react-cookie-consent";

class HomePage extends React.Component {
  render() {
    const { site } = this.props.data;
    return (
      <div>
        <Helmet>
          <title>Biljni terariji - Terraform - Hrvatska</title>
          <meta
            name="description"
            content="Unikatni biljni terariji izrađeni u Terraform studiju savršen su poklon za svaki dom! Naručite svoj personalizirani biljni terarij!"
          />
        </Helmet>
        <Layout>
          <Hero id="početna" />
          <About id="terraform" />
          <Services id="proizvodi" />
          {/* <Portfolio id="katalog" /> */}
          <Testimonials id="dojmovi" />
          <Contact id="kontakt" />
          <CookieConsent
            location="bottom"
            buttonText="Slažem se!"
            cookieName="myAwesomeCookieName2"
            style={{ background: "black" }}
            buttonStyle={{ background: "#eb83f8", fontSize: "13px" }}
            expires={150}
          >
            Ova web stranica radi boljeg rada i poboljšane funkcionalnosti
            koristi kolačiće (eng. cookies) i slične tehnologije. Ako nastavite
            s pregledom stranice, smatrat ćemo da ste suglasni s navedenom
            uporabom.
          </CookieConsent>
        </Layout>
        <Spinner duration={1000} />
      </div>
    );
  }
}

export default HomePage;

export const pageQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`;
