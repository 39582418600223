import React from "react";
import Particles from "react-particles-js";
import Progress from "components/progress";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { StaticQuery, graphql } from "gatsby";
import ThemeContext from "../../context";
import "./styles.scss";

class Hero extends React.Component {
  static contextType = ThemeContext;

  render() {
    return (
      <section
        id={`${this.props.id}`}
        className="about"
        style={{ height: this.context.height }}
      >
        <Row>
          <Col md={6} className="content">
            {this.particles()}
            <div className="content-text">
              <div className="line-text">
                <h4>O Terraformu</h4>
              </div>
              <h3>
                Dobrodošli u kreativni studio dizajna terarija i obrade stakla
              </h3>
              <div className="separator" />
              <p>
                Moj prvi susret sa zatvorenim biljnim terarijem se dogodio davne
                2012. u poljoprivrednoj školi M.A.R. u Slavonskom Brodu gdje
                započinje moja ljubav prema biljkama. Bila je to ljubav na prvi
                pogled i 2013. godine iz radoznalosti izrađujem svoj prvi
                zatvoreni terarij. Za vrijeme svog boravka u Berlinu otvaram
                online shop na Etsy platformi gdje započinjem prodaju biljnih
                terarija i terarij kitova za kupce unutar EU. Osim toga,
                obrađujem ravno staklo i izrađujem terarije tehnikom lemljenja.
                Svaki <strong>biljni terarij</strong> je unikatan i moguće je
                napraviti prilagodbu za svaki dom ili ured zasebno (veličina,
                boja i dizajn), ali o tome više u fotogaleriji ili na mom{" "}
                <a
                  className="socialMediaInstagram"
                  href="https://www.instagram.com/terraform_studio/"
                  target="_blank"
                >
                  Instagram
                </a>
                /
                <a
                  className="socialMediaFacebook"
                  href="https://www.facebook.com/terraformShop"
                  target="_blank"
                >
                  Facebook
                </a>{" "}
                profilu.
              </p>
            </div>
          </Col>
          <Col md={6} className="skills">
            <div className="line-text"></div>
            <div className="terrariumFront">
              <img
                src={this.props.mainImg.childImageSharp.fluid.src}
                alt="Biljni terarij"
              />
              {/* <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/CE0U9pZZJbM"
                title="Luka - proces izrade terarija"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe> */}
              {/* <Progress name="Web Design" value={90} delay={1100} />
              <Progress name="Angular" value={50} delay={1100} />
              <Progress name="React" value={80} delay={1100} />
              <Progress name="Vue" value={40} delay={1100} />
              <Progress name="MongoDB" value={100} delay={1100} />
              <Progress name="CSS" value={50} delay={1100} /> */}
            </div>
          </Col>
        </Row>
      </section>
    );
  }

  particles() {
    return (
      <Particles
        className="particles"
        params={{
          particles: {
            number: {
              value: 50,
              density: {
                enable: false,
                value_area: 5000
              }
            },
            color: {
              value: "#eb83f8"
            },
            line_linked: {
              enable: true,
              opacity: 0.5,
              color: "#000"
            },
            size: {
              value: 4
            }
          },
          retina_detect: true
        }}
      />
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        icons: allFile(
          filter: {
            extension: { regex: "/(png)/" }
            relativeDirectory: { eq: "icons" }
          }
        ) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 100) {
                  src
                }
              }
            }
          }
        }
        Img: file(relativePath: { eq: "clorophyll.png" }) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              src
            }
          }
        }
      }
    `}
    render={({ icons, Img }) => <Hero icons={icons} mainImg={Img} {...props} />}
  />
);
