import React from "react";
import Particles from "react-particles-js";
import "./styles.scss";
import { StaticQuery, graphql } from "gatsby";
import { Row, Col } from "react-bootstrap";
import Glitch from "components/glitch";
import Typewriter from "typewriter-effect";
import ThemeContext from "../../context";
class Hero extends React.Component {
  static contextType = ThemeContext;

  render() {
    return (
      <section
        id={`${this.props.id}`}
        className="hero"
        style={{ height: "100vh" }}
      >
        {this.particles()}
        <Row>
          <Col className="content">
            <div className="content-text">
              <div className="line-text">
                <h1> Biljni terariji</h1>{" "}
              </div>{" "}
              <Glitch text="Terraform" />
              <Typewriter
                options={{
                  strings: [
                    "izrada & dizajn terarija",
                    "samoodrživi ekosistemi",
                    "obrada i lemljenje stakla"
                  ],
                  autoStart: true,
                  loop: true
                }}
              />{" "}
              <button
                className="hover-button"
                onClick={() => window.open("https://www.terraform.hr/shop")}
              >
                <span> Online shop </span>{" "}
              </button>{" "}
            </div>{" "}
            {/* {this.icons()}{" "} */}
          </Col>{" "}
        </Row>{" "}
      </section>
    );
  }

  icons() {
    let images = [
      {
        id: 1,
        src: "/img/images/1.png"
      },
      {
        id: 2,
        src: "/img/images/2.png"
      },
      {
        id: 3,
        src: "/img/images/3.png"
      },
      {
        id: 4,
        src: "/img/images/4.png"
      },
      {
        id: 5,
        src: "/img/images/5.png"
      },
      {
        id: 6,
        src: "/img/images/6.png"
      },
      {
        id: 7,
        src: "/img/images/7..png"
      },
      {
        id: 8,
        src: "/img/images/8.png"
      },
      {
        id: 9,
        src: "/img/images/9.png"
      }
    ];
    return images.map((value, index) => {
      return (
        <img
          src={value.src}
          className={`animated fadeIn move-${
            Math.floor(Math.random() * 15) % 3 === 0 ? "up" : "down"
          } float-image zIndex`}
          style={{
            left: `${index * 10}%`,
            bottom: `${Math.random() *
              (+(index % 2 === 0 ? 80 : 20) - +(index % 2 === 0 ? 70 : 10)) +
              +(index % 2 === 0 ? 70 : 10)}%`,
            // height: `${20}%`,
            width: `${15}%`
          }}
          alt="shape"
          key={index}
        />
      );
    });
  }

  particles() {
    return (
      <Particles
        className="particles"
        params={{
          particles: {
            number: {
              value: 90,
              density: {
                enable: false,
                value_area: 5000
              }
            },
            color: {
              value: "#eb83f8"
            },
            line_linked: {
              enable: true,
              opacity: 0.3,
              color: "#000"
            },
            size: {
              value: 4
            }
          },
          retina_detect: true
        }}
      />
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        icons: allFile(
          filter: {
            extension: { regex: "/(png)/" }
            relativeDirectory: { eq: "icons" }
          }
        ) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 100) {
                  src
                }
              }
            }
          }
        }
        Img: file(relativePath: { eq: "luka.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              src
            }
          }
        }
      }
    `}
    render={({ icons, Img }) => <Hero icons={icons} mainImg={Img} {...props} />}
  />
);
