import React from "react";
import { Link } from "gatsby";
import "./styles.scss";
import Glitch from "components/glitch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faBars } from "@fortawesome/free-solid-svg-icons";
var scrollToElement = require("scroll-to-element");

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
    this.sections = [
      {
        name: "Početna"
      },
      {
        name: "Terraform"
      },
      {
        name: "Proizvodi"
      },
      // {
      //   name: "Katalog"
      // },
      {
        name: "Dojmovi"
      },
      {
        name: "Kontakt"
      },
      {
        name: "Shop"
      }
    ];
  }

  navScroll(id, v) {
    this.setState({ show: false });
    const el = document.getElementById(id);
    scrollToElement(el, {
      offset: 0,
      ease: "in-out-expo",
      duration: 2000
    }).on("end", () => {
      this.props.change(v);
    });
  }

  render() {
    return (
      <div>
        <div className="opener">
          <FontAwesomeIcon
            icon={faBars}
            className="closeNav"
            onClick={() => this.setState({ show: true })}
          />
        </div>
        <div className={`navigation ${this.state.show ? "active" : ""}`}>
          <FontAwesomeIcon
            icon={faTimes}
            className="closeNav"
            onClick={() => this.setState({ show: false })}
          />
          <div className="logo">
            {/* <span className="nav-logo">terraform</span> */}
            <Glitch className="test12" text="Terraform" />
          </div>
          <div className="links">
            <ul>{this.items()}</ul>
          </div>
        </div>
      </div>
    );
  }

  items() {
    return this.sections.map((value, index) => {
      if (value.name !== "Shop") {
        return (
          <li key={index}>
            <button
              className="navButton"
              onClick={() => this.navScroll(value.name.toLowerCase(), index)}
            >
              {value.name}
            </button>
          </li>
        );
      } else {
        return (
          <li key={index}>
            <a href="https://terraform.hr/shop" className="navButton">
              Shop
            </a>
          </li>
        );
      }
    });
  }
}
export default Navigation;
