import React from "react";
import emailjs from "emailjs-com";
import { sendEmail } from "emailjs-com";
import EmailValidator from "email-validator";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebook } from "@fortawesome/free-brands-svg-icons";
import swal from "sweetalert2";
import "./styles.scss";
import { Row, Col, NavbarBrand } from "react-bootstrap";
import AnimationContainer from "components/animation-container";
import BaffleText from "components/baffle-text";
import ThemeContext from "../../context";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      error: false,
      show: false,
      validEmail: true
    };
    this.show = this.show.bind(this);
  }
  static contextType = ThemeContext;

  sendEmail = e => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_5oc1oms",
        "template_pmf3ug8",
        e.target,
        "user_UUXmh5xsXaoGUWHe4dyn6"
      )
      .then(
        result => {
          console.log(result.text);
          swal.fire(
            "Poruka poslana",
            "Hvala Vam na poruci! Odgovoriti ću Vam ubrzo! :)",
            "success"
          );
        },
        error => {
          console.log(error.text);
          swal.fire("Message Error", error.text, "error");
        }
      );
    e.target.reset();
  };

  show() {
    this.setState({ show: true });
  }

  check(val) {
    if (this.state.error && val === "") {
      return false;
    } else {
      return true;
    }
  }

  submit() {
    if (
      this.state.name === "" ||
      this.state.email === "" ||
      this.state.message === ""
    ) {
      this.setState({ error: true });
    } else {
      this.setState({ error: false });
    }
  }

  emailValidation = e => {
    this.setState({ email: e.target.value });
    this.setState({ validEmail: EmailValidator.validate(e.target.value) });
  };
  render() {
    return (
      <section
        id={`${this.props.id}`}
        className="contact"
        style={{ height: this.context.height }}
      >
        <Row>
          <Col md={2} className="side">
            <h2>
              <BaffleText
                text="Kontakt"
                revealDuration={500}
                revealDelay={500}
                parentMethod={this.show}
                callMethodTime={1100}
              />
            </h2>
          </Col>
          <Col md={6} className="form">
            {this.form()}
            <div className="social social_icons">
              <FontAwesomeIcon
                icon={faInstagram}
                className="social_icon"
                onClick={() =>
                  window.open("https://www.instagram.com/terraform_studio/")
                }
              />
              <FontAwesomeIcon
                icon={faFacebook}
                className="social_icon"
                onClick={() =>
                  window.open("https://www.facebook.com/terraformShop")
                }
              />
            </div>
            <br />
            <Link className="terms" to="/terms">
              Uvjeti i odredbe
            </Link>
            <Link className="privacy" to="/privacy">
              Pravila zaštite podataka i privatnosti
            </Link>
            <Link className="terms" to="/shipping">
              Pravila slanja narudžbe
            </Link>
            <Link className="privacy" to="/return">
              Pravila povrata
            </Link>
            <Link className="terms" to="/impressum">
              Impressum
            </Link>
            <h6 className="privacy">
              {" "}
              © {new Date().getFullYear()} | Izrada stranice: Luka - dizajner,
              programer, entuzijast i ljubitelj biljaka
            </h6>
          </Col>
          <Col md={5} className="map">
            {this.map()}
          </Col>
        </Row>
      </section>
    );
  }

  form() {
    if (this.state.show || this.context.height === "auto") {
      return (
        <AnimationContainer delay={0} animation="fadeInUp fast">
          <div className="form-container">
            <div className="line-text">
              <h4>Pošaljite poruku</h4>
              <form onSubmit={this.sendEmail}>
                <AnimationContainer delay={50} animation="fadeInUp fast">
                  <div className="form-group">
                    <input
                      type="text"
                      className={`name ${
                        this.check(this.state.name) ? "" : "error"
                      }`}
                      placeholder="Ime"
                      onChange={e => this.setState({ name: e.target.value })}
                      name="name"
                    />
                  </div>
                </AnimationContainer>
                <AnimationContainer delay={100} animation="fadeInUp fast">
                  <div className="form-group">
                    <input
                      type="text"
                      className={`email ${
                        this.state.validEmail ? "" : "error"
                      }`}
                      placeholder="Email"
                      onChange={this.emailValidation}
                      name="email"
                    />
                  </div>
                </AnimationContainer>
                <AnimationContainer delay={200} animation="fadeInUp fast">
                  <div className="form-group">
                    <textarea
                      className={`message ${
                        this.check(this.state.message) ? "" : "error"
                      }`}
                      placeholder="Poruka"
                      onChange={e => this.setState({ message: e.target.value })}
                      name="message"
                    ></textarea>
                  </div>
                </AnimationContainer>
                <AnimationContainer delay={250} animation="fadeInUp fast">
                  <div className="submit">
                    <button
                      className={`hover-button ${
                        !this.state.validEmail ||
                        !this.state.name ||
                        !this.state.message
                          ? "error"
                          : ""
                      }`}
                      onClick={() => this.submit()}
                      disabled={
                        !this.state.name ||
                        !this.state.email ||
                        !this.state.message ||
                        !this.state.validEmail
                      }
                    >
                      <span>Pošalji</span>
                    </button>
                  </div>
                </AnimationContainer>
              </form>
            </div>
          </div>
        </AnimationContainer>
      );
    }
  }

  map() {
    if (this.state.show || this.context.height === "auto") {
      return (
        <AnimationContainer
          delay={1000}
          animation="fadeIn fast"
          height={this.context.height}
        >
          <iframe
            title="map"
            width="100%"
            height="100%"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2812.55401338462!2d17.993563515812784!3d45.1758803606559!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475dba388fc2e0ab%3A0x62a53bede4f387a2!2sDunavska%20ul.%2014%2C%2035000%2C%20Slavonski%20Brod!5e0!3m2!1sen!2shr!4v1636639511170!5m2!1sen!2shr"
          />
        </AnimationContainer>
      );
    }
  }
}

export default Contact;
